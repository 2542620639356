// You can delete this file if you're not using it
import React from 'react'
import Transition from './src/components/Transition'

export const wrapPageElement = ({ element, props }) => {
  return <Transition {...props}>{element}</Transition>
}

// Needed for smooth page transition and timing. Else new page renders before fade out.
export const shouldUpdateScroll = () => false
export const onRouteUpdate = ({ location }) =>
  window.setTimeout(() => {
    switch (location.hash) {
      case '#why-us':
        document.getElementById('why-us') && document.getElementById('why-us').scrollIntoView()
        break
      case '#our-team':
        document.getElementById('our-team') && document.getElementById('our-team').scrollIntoView()
        break
      case '#clients':
        document.getElementById('clients') && document.getElementById('clients').scrollIntoView()
        break
      case '#location':
        document.getElementById('location') && document.getElementById('location').scrollIntoView()
        break
      case '#message':
        document.getElementById('message') && document.getElementById('message').scrollIntoView()
        break
      default:
        window.scrollTo(0, 0)
    }
  }, 300)
