module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LaMar Software","short_name":"LaMar Software","start_url":"/","background_color":"#ffffff","theme_color":"#1989cc","display":"standalone","icon":"src/images/logo_lamar_no-pad.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5dd178ce65bd10f71b301ec7b5e88355"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
