// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-selecting-a-software-company-index-js": () => import("./../../../src/pages/blog/selecting-a-software-company/index.js" /* webpackChunkName: "component---src-pages-blog-selecting-a-software-company-index-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-apps-js": () => import("./../../../src/pages/privacy-policy/apps.js" /* webpackChunkName: "component---src-pages-privacy-policy-apps-js" */),
  "component---src-pages-terms-of-use-apps-js": () => import("./../../../src/pages/terms-of-use/apps.js" /* webpackChunkName: "component---src-pages-terms-of-use-apps-js" */)
}

